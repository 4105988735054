<template>
<div class="row">
    <h5 class="col-12 mt-3">Nieuws</h5>
    <div class="col-12 news-columns">

      <ul class="nav nav-pills mb-2 nav-justified" id="pills-tab" role="tablist">

        <!-- Temporary disabled by LX-8235 -->
        <!-- <li class="nav-item" role="presentation">
          <a class="nav-link" id="pills-team-tab" data-toggle="pill" href="#pills-team" role="tab" aria-controls="pills-home" aria-selected="false">Team</a>
        </li> -->
        
        <li class="nav-item" role="presentation">
          <a class="nav-link active" id="pills-club-tab" data-toggle="pill" href="#pills-club" role="tab" aria-controls="pills-profile" aria-selected="true">Club</a>
        </li>

        <!-- Temporary disabled by LX-8235 -->
        <!-- <li class="nav-item" role="presentation">
          <a class="nav-link" id="pills-hockey-tab" data-toggle="pill" href="#pills-hockey" role="tab" aria-controls="pills-contact" aria-selected="false">Hockey.nl</a>
        </li> -->

      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade" id="pills-team" role="tabpanel">
          Geen actief teamnieuws

        </div>
        <div class="tab-pane fade show active" id="pills-club" role="tabpanel">
          <div class="card mb-3">
            <img src="https://almeersehockeyclub.nl/files/nieuws/Enjoy%20&amp;%20Perform%20(1).jpg" class="card-img-top" alt="...">
            <div class="card-body">

              <h6 class="card-title mb-2 font-weight-bold"><a href="#" class="stretched-link text-dark">Nieuwsbericht met een titel</a></h6>
              <p class="card-text">
                Hebben jullie ons vakantieprogramma al langs zien komen in de app?! De Almeerse Hockey Academy...</p>

              <small class="text-muted float-left">10 reacties</small>
              <small class="text-muted float-right">23 maart 2020</small>
            </div>
          </div>

          <div class="card mb-3">
            <img src="https://almeersehockeyclub.nl/files/nieuws/Enjoy%20&amp;%20Perform%20(1).jpg" class="card-img-top" alt="...">
            <div class="card-body">

              <h6 class="card-title mb-2 font-weight-bold"><a href="#" class="stretched-link text-dark">Nieuwsbericht met een langere titel</a></h6>
              <p class="card-text">
                Hebben jullie ons vakantieprogramma al langs zien komen in de app?! De Almeerse Hockey Academy...</p>
              <small class="text-muted float-left">10 reacties</small>
              <small class="text-muted float-right">23 maart 2020</small>
            </div>
          </div>

        </div>
        <div class="tab-pane fade" id="pills-hockey" role="tabpanel" aria-labelledby="pills-hockey-tab">
          <div class="card mb-3">
            <img src="https://almeersehockeyclub.nl/files/nieuws/Enjoy%20&amp;%20Perform%20(1).jpg" class="card-img-top" alt="...">
            <div class="card-body">

              <h6 class="card-title mb-2 font-weight-bold"><a href="#" class="stretched-link text-dark">Nieuwsbericht met een titel</a></h6>
              <p class="card-text">
                Hebben jullie ons vakantieprogramma al langs zien komen in de app?! De Almeerse Hockey Academy...</p>

              <small class="text-muted float-left">10 reacties</small>
              <small class="text-muted float-right">23 maart 2020</small>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class News extends Vue {
}
</script>

<style scoped>
</style>