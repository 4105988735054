var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("h5", { staticClass: "col-12 mt-3" }, [_vm._v("Nieuws")]),
      _c("div", { staticClass: "col-12 news-columns" }, [
        _c(
          "ul",
          {
            staticClass: "nav nav-pills mb-2 nav-justified",
            attrs: { id: "pills-tab", role: "tablist" },
          },
          [
            _c(
              "li",
              { staticClass: "nav-item", attrs: { role: "presentation" } },
              [
                _c(
                  "a",
                  {
                    staticClass: "nav-link active",
                    attrs: {
                      id: "pills-club-tab",
                      "data-toggle": "pill",
                      href: "#pills-club",
                      role: "tab",
                      "aria-controls": "pills-profile",
                      "aria-selected": "true",
                    },
                  },
                  [_vm._v("Club")]
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "tab-content", attrs: { id: "pills-tabContent" } },
          [
            _c(
              "div",
              {
                staticClass: "tab-pane fade",
                attrs: { id: "pills-team", role: "tabpanel" },
              },
              [_vm._v(" Geen actief teamnieuws ")]
            ),
            _c(
              "div",
              {
                staticClass: "tab-pane fade show active",
                attrs: { id: "pills-club", role: "tabpanel" },
              },
              [
                _c("div", { staticClass: "card mb-3" }, [
                  _c("img", {
                    staticClass: "card-img-top",
                    attrs: {
                      src: "https://almeersehockeyclub.nl/files/nieuws/Enjoy%20&%20Perform%20(1).jpg",
                      alt: "...",
                    },
                  }),
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "h6",
                      { staticClass: "card-title mb-2 font-weight-bold" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "stretched-link text-dark",
                            attrs: { href: "#" },
                          },
                          [_vm._v("Nieuwsbericht met een titel")]
                        ),
                      ]
                    ),
                    _c("p", { staticClass: "card-text" }, [
                      _vm._v(
                        " Hebben jullie ons vakantieprogramma al langs zien komen in de app?! De Almeerse Hockey Academy..."
                      ),
                    ]),
                    _c("small", { staticClass: "text-muted float-left" }, [
                      _vm._v("10 reacties"),
                    ]),
                    _c("small", { staticClass: "text-muted float-right" }, [
                      _vm._v("23 maart 2020"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "card mb-3" }, [
                  _c("img", {
                    staticClass: "card-img-top",
                    attrs: {
                      src: "https://almeersehockeyclub.nl/files/nieuws/Enjoy%20&%20Perform%20(1).jpg",
                      alt: "...",
                    },
                  }),
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "h6",
                      { staticClass: "card-title mb-2 font-weight-bold" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "stretched-link text-dark",
                            attrs: { href: "#" },
                          },
                          [_vm._v("Nieuwsbericht met een langere titel")]
                        ),
                      ]
                    ),
                    _c("p", { staticClass: "card-text" }, [
                      _vm._v(
                        " Hebben jullie ons vakantieprogramma al langs zien komen in de app?! De Almeerse Hockey Academy..."
                      ),
                    ]),
                    _c("small", { staticClass: "text-muted float-left" }, [
                      _vm._v("10 reacties"),
                    ]),
                    _c("small", { staticClass: "text-muted float-right" }, [
                      _vm._v("23 maart 2020"),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "tab-pane fade",
                attrs: {
                  id: "pills-hockey",
                  role: "tabpanel",
                  "aria-labelledby": "pills-hockey-tab",
                },
              },
              [
                _c("div", { staticClass: "card mb-3" }, [
                  _c("img", {
                    staticClass: "card-img-top",
                    attrs: {
                      src: "https://almeersehockeyclub.nl/files/nieuws/Enjoy%20&%20Perform%20(1).jpg",
                      alt: "...",
                    },
                  }),
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "h6",
                      { staticClass: "card-title mb-2 font-weight-bold" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "stretched-link text-dark",
                            attrs: { href: "#" },
                          },
                          [_vm._v("Nieuwsbericht met een titel")]
                        ),
                      ]
                    ),
                    _c("p", { staticClass: "card-text" }, [
                      _vm._v(
                        " Hebben jullie ons vakantieprogramma al langs zien komen in de app?! De Almeerse Hockey Academy..."
                      ),
                    ]),
                    _c("small", { staticClass: "text-muted float-left" }, [
                      _vm._v("10 reacties"),
                    ]),
                    _c("small", { staticClass: "text-muted float-right" }, [
                      _vm._v("23 maart 2020"),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }